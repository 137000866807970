
// the global objects that must be passed to this module
var jQ;
var DEBUG;

"use strict";

function initTrailingHeadings() {
    var root = jQ('html');
    var scrollEndTimerId = null;
    var prevScrollY = window.scrollY;
    window.addEventListener('scroll', function () {
        if (scrollEndTimerId != null) {
            clearTimeout(scrollEndTimerId);
        }
        if (prevScrollY > window.scrollY) {
            root.addClass('scrolling-up');
        } else {
            root.addClass('scrolling-down');
        }
        prevScrollY = window.scrollY;

        scrollEndTimerId = setTimeout(function () {
            root.removeClass('scrolling-down scrolling-up');
        }, 50);
    });
}

/****** Exported functions ******/

export function init(jQuery, debug) {

    jQ = jQuery;
    DEBUG = debug;

    if (DEBUG) console.info("TrailingHeadings.init()");

    initTrailingHeadings();
}
