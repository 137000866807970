// the global objects that must be passed to this module
var jQ;
var DEBUG;
var VERBOSE;
var WINDOW;

"use strict";

function onScrollCallback() {

    var threshHold = jQ(document).scrollTop() + WINDOW.height() - 100;

    var elements = jQ(".animate-on-scroll:not(.appeared)");
    if (elements) {
        elements.each(function (index, element) {

            var $element = jQ(element);
            if (threshHold >= $element.offset().top) {
                log("AnimateOnScroll: animating")
                $element.css('animation-delay', (index * 0.2) + "s")
                $element.addClass("appeared");
            }
        });
    }
    else {
        log("AnimateOnScroll: removed scroll listener")
        WINDOW.off("scroll resize", onScrollCallback);
    }
}

function initAnimateOnScroll() {

    var elements = jQ(".animate-on-scroll");
    if (elements) {
        log("AnimateOnScroll: Elements found: " + elements.length);

        WINDOW.on("scroll resize", onScrollCallback);

        onScrollCallback();
    }
}

function log() {
    if (DEBUG) console.info.apply(this, arguments)
}

export function init(jQuery, debug) {

    jQ = jQuery;
    WINDOW = jQ(window);
    DEBUG = debug;
    VERBOSE = DEBUG && (Mercury.getParameter("jsverbose") != null);

    log("AnimateOnScroll.init()");

    initAnimateOnScroll();
}